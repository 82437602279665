import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import {  FormPlugin, FormGroupPlugin, FormInputPlugin,FormTextareaPlugin , FormFilePlugin, FormSelectPlugin, AlertPlugin, TabsPlugin , LinkPlugin, CalendarPlugin, ModalPlugin, ButtonPlugin,  FormDatepickerPlugin, ListGroupPlugin, NavbarPlugin, SpinnerPlugin, ToastPlugin      } from 'bootstrap-vue'
Vue.use(ModalPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(ListGroupPlugin)
Vue.use(NavbarPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ToastPlugin)
Vue.use(CalendarPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(LinkPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormSelectPlugin)
Vue.use(AlertPlugin)
Vue.use(TabsPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormFilePlugin)

import { BIcon, BIconCaretLeftFill, BIconCaretRightFill, BIconCaretDownFill, BIconCaretUpFill, BIconArrowRepeat, BIconTrash, BIconPersonCircle, BIconLayoutTextSidebar, BIconGearFill, BIconPencilSquare, BIconFilter, BIconToggleOn, BIconFileEarmarkText, BIconFileFont, BIconCalendarDate, BIconDownload, BIconIntersect, BIconExclamationCircle, BIconCheckCircle, BIconXCircle, BIconPlusCircleFill, BIconInfoCircle, BIconArrowsExpand, BIconChatRightQuoteFill, BIconFront, BIconSortNumericDown, } from 'bootstrap-vue'
Vue.component('BIcon', BIcon); Vue.component('BIconCaretLeftFill', BIconCaretLeftFill); Vue.component('BIconCaretRightFill', BIconCaretRightFill); Vue.component('BIconCaretDownFill', BIconCaretDownFill); Vue.component('BIconCaretUpFill', BIconCaretUpFill); Vue.component('BIconArrowRepeat', BIconArrowRepeat); Vue.component('BIconTrash', BIconTrash); Vue.component('BIconPersonCircle', BIconPersonCircle); Vue.component('BIconLayoutTextSidebar', BIconLayoutTextSidebar); Vue.component('BIconGearFill', BIconGearFill); Vue.component('BIconPencilSquare', BIconPencilSquare); Vue.component('BIconFilter', BIconFilter); Vue.component('BIconToggleOn', BIconToggleOn); Vue.component('BIconFileEarmarkText', BIconFileEarmarkText); Vue.component('BIconFileFont', BIconFileFont); Vue.component('BIconCalendarDate', BIconCalendarDate); Vue.component('BIconDownload', BIconDownload); Vue.component('BIconIntersect', BIconIntersect); Vue.component('BIconExclamationCircle', BIconExclamationCircle); Vue.component('BIconCheckCircle', BIconCheckCircle); Vue.component('BIconXCircle', BIconXCircle); Vue.component('BIconPlusCircleFill', BIconPlusCircleFill); Vue.component('BIconInfoCircle', BIconInfoCircle); Vue.component('BIconArrowsExpand', BIconArrowsExpand); Vue.component('BIconChatRightQuoteFill', BIconChatRightQuoteFill); Vue.component('BIconFront', BIconFront); Vue.component('BIconSortNumericDown', BIconSortNumericDown);




const requireComponent = require.context(
  // Относительный путь до каталога компонентов
  './components',
  // Обрабатывать или нет подкаталоги
  true,
  // Регулярное выражение для определения файлов базовых компонентов
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Получение конфигурации компонента
  const componentConfig = requireComponent(fileName)
   const componentName = fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
  // Глобальная регистрация компонента
  Vue.component(
     componentName,
    // Поиск опций компонента в `.default`, который будет существовать,
    // если компонент экспортирован с помощью `export default`,
    // иначе будет использован корневой уровень модуля.
    componentConfig.default || componentConfig
  )
})

new Vue({
  router,
  render: h => h(App),
    data:{
        userid: sessionStorage.userid,
        host: 'http://lk.getcall.pro/',
        token: sessionStorage.token,
    },
  created: function(){
        var _this = this;
        this.$router.onReady(function(){
        console.log(_this.$router.currentRoute)
        console.log(_this.$router.currentRoute.params)
        if( !_this.userid && !_this.$router.currentRoute.params.code ){
            // this.$router.go('auth');
        }
        }) 

  },
  methods:{
     load: function(){
        var _this = this;
        this.GET('index').then(function(j){
             _this.info = j;
        }) 
     }, 
     action: function(a){
            if( a=='main' ) a='';
            this.$router.go(a);
     }, 
      set: function (com, method, data, type='POST'){
           
           return this.post('api.php?action='+com+'&method='+method + '&token='+sessionStorage.token, data, type);
            
      },
      
      url:function(url){
        return this.host+'/'+url;
      },
      post: async function(action = '', data = {}, method='POST') {
        // Значения по умолчанию обозначены знаком *
          let url = this.url(action);
 
          var o = {
              method: method, // *GET, POST, PUT, DELETE, etc.
              mode: 'cors', // no-cors, cors, *same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'include', // include, *same-origin, omit
              headers: { 'Accept': '*/*' },
          }

          
          if( method == 'FORM' ){
              //o.headers['Content-Type'] =  'multipart/form-data';
              o.body = data;
              o.method = 'POST';
          }
          else{
             o.headers['Content-Type'] = 'application/json;charset=utf-8';
             if(data && method!='GET'){
              if(typeof(data)=='string') o.body = data; 
              else o.body = JSON.stringify(data);
             }
          }
          console.log(method, url, o);
          //if( method == 'POST' ) return;
          try{
              let res = await fetch(url, o);

              var json = await res.json();
              console.log(json);
              if(json){
                  if( json.error ){
                        if( json.error == 'autherror' ){
                              console.log( console.log(document.cookie) );
                              return this.$router.replace('auth');
                        }
                     //return false;
                  }else{
                    if( json.message ){
                       this.Message(json.message);
                    }
                  }
                   return  json;
              }else{
                    this.Error('Не удалось получить данные', 'request:'+url);
                    return false;
              } 
          } catch (error) {
               this.Error(error, 'request:'+url);
          }
      },
      POST: function(url, data) {
          return this.post(url,data,'POST');
      },
      GET: function(url) {
          return this.post(url,null,'GET');
      },
      Error: function(mess, type=false) {
          console.log('--- ОШИБКА ----', mess, type);
      },

  }
}).$mount('#app')

<template>
<div class="card p-4" style="max-width:500px; margin: 30px auto">
  
    <h1>Авторизация</h1>
    <b-form  @submit.stop.prevent>
        
        <div class="form-group">
          <label for="InputCode">Код авторизации</label>
          <b-input v-model.lazy="code" :state="valid_code" id="InputCode"></b-input>
          <b-form-invalid-feedback :state="valid_code">
              Неверный код авторизации.
          </b-form-invalid-feedback>

        </div>

        <div class="form-group Password">
          <label for="InputPassword">Придумайте новый пароль:</label>
          <b-input v-model.lazy="pass" :state="pass_change_check_1" type="password" class="form-control"></b-input>
          <b-form-invalid-feedback :state="pass_change_check_1">
              Пароль должен быть от 6 до 12 символов длинной
          </b-form-invalid-feedback>
          
        </div>

        <div class="form-group Password">
          <label for="InputPassword">Ваш новый пароль (еще раз)</label>
          <b-input v-model.lazy="pass2" :state="pass_change_check_2" type="password" class="form-control"></b-input>
          <b-form-invalid-feedback :state="pass_change_check_2">
              Пароли не совпадают
          </b-form-invalid-feedback>
          
        </div>
          <b-form-invalid-feedback :state="novalid_code" class="alert alert-danger">
             Код авторизации недействителен или введен ошибочно.
          </b-form-invalid-feedback>
        <button  v-on:click="code_pass" class="btn btn-primary mr-2">Сохранить</button>
        <button  v-on:click="cancel" class="btn btn-secondary">Отмена</button>
        
  </b-form>

</div>
</template>

<script>


export default {
  data: function(){ return {
                    code: '', 
                    error_code: true,
                    novalid_code:true,
                    pass: '',
                    pass2: ''
  }},
  
  created: function() {
            if( this.$route.params.code ){
                 console.log(this.$route.params);
                 this.code = this.$route.params.code;
                 this.novalid_code = true;
            }
  },

               methods: {

                    code_pass: function(){
                         var _this = this;
                         if( this.pass_change_check_2 ) this.$root.set('auth', 'code', { code:this.code, pass: this.pass } ).then(function(data){
                              if( data.id){
                                   sessionStorage.userid = data.id;
                                   sessionStorage.token = data.token;
                                   sessionStorage.removeItem('confirmRegistration');
                                   _this.$root.action('main');
                              }else{
                                  _this.novalid_code = false;
                                 console.log(data, data.error );
                              }
                         })
                    },
                    cancel: function(){
                          sessionStorage.removeItem('confirmRegistration');
                          this.$root.action('auth');
                    },
               },
               computed: {
                    valid_code: function(){
                        return this.code.length == 40
                    },
                    pass_change_check_1: function(){
                          return this.pass.length > 5 && this.pass.length < 13
                     },
                     pass_change_check_2: function(){
                          return ( this.pass_change_check_1 && this.pass2 == this.pass );
                     },
               }
}   
</script>


import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../components/Main.vue')
  },
/* {
    path: '/main',
    component: () => import('../components/Main.vue')
}, */
{
    path: '/config',
    component: () => import('../components/Config.vue'),
    mode:'hash'
}, 
 
{
    path: '/icons',
    component: () => import('../components/Icons.vue'),
    mode:'hash'
},
{
    path: '/auth',
    component: () => import('../components/Auth.vue'),
    mode:'hash'
},
{
    path: '/confirm/:code',
    component: () => import('../components/Confirm.vue'),
    mode:'hash'
},
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})



export default router
